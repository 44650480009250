import { Burger, Divider, Stack, createStyles } from '@mantine/core';
import Cookies from 'js-cookie';
import useTranslation from 'next-translate/useTranslation';
import React, { useContext, useMemo } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { UnstyledNextLink } from '~/components/shared/UnstyledNextLink/UnstyledNextLink';
import { Routes } from '~/domains/common/constants/routes';
import { useTreatments } from '~/domains/common/hooks/useTreatments';

import tc from '../../testing.const';
import HeaderDrawer from '../common/HeaderDrawer';
import { HeaderMenusContext } from '../HeaderMenusContext';
import NotificationBadge from '../NotificationBadge';
import useCommonStyles from '../common/useCommonStyles';

const useStyles = createStyles((theme) => ({
  burgerButton: {
    marginLeft: 'auto',
  },
  navLinkBadge: {
    display: 'inline-block',
    marginLeft: theme.other.spacing._4,
  },
  burgerContainer: {
    position: 'relative',
  },
  burgerNavLinkBadge: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

function MobileLogoutMenu() {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { t } = useTranslation('common');
  const { menuId, onMenuOpen, onMenuToggle } = useContext(HeaderMenusContext);
  const isOpen = menuId === 'user';
  const propsEnabled = useTreatments([FEATURE.ENABLE_PROPS]);
  const isPropsEnabled =
    propsEnabled.isReady && propsEnabled?.treatments[FEATURE.ENABLE_PROPS].treatment === 'on';

  const navLinks = useMemo(
    () => [
      [
        {
          label: t('appShellHeader.home'),
          href: Routes.home(),
        },
        {
          label: t('appShellHeader.contestLobby'),
          href: Routes.contestLobby(),
        },
        ...(isPropsEnabled
          ? [
              {
                href: Routes.quickPicksBoard(),
                label: (
                  <>
                    {t('appShellHeader.quickPicks')}
                    {!Cookies.get('quickPicksTutorialSeen') && (
                      <div className={classes.navLinkBadge}>
                        <NotificationBadge showEmpty />
                      </div>
                    )}
                  </>
                ),
              },
            ]
          : []),
      ],
      [
        {
          label: t('appShellHeader.logIn'),
          href: Routes.signIn(),
        },
      ],
    ],
    [classes.navLinkBadge, isPropsEnabled, t]
  );

  return (
    <>
      <div className={classes.burgerContainer}>
        <Burger
          opened={isOpen}
          onClick={() => {
            onMenuToggle('user');
          }}
          color="white"
          className={classes.burgerButton}
          size={18}
          data-test-id={tc.components.appShellHeader.mobileMenuButton}
        />
        {!Cookies.get('quickPicksTutorialSeen') && (
          <div className={classes.burgerNavLinkBadge}>
            <NotificationBadge showEmpty />
          </div>
        )}
      </div>

      <HeaderDrawer
        isOpen={isOpen}
        onOpen={(v) => {
          onMenuOpen(v ? 'user' : undefined);
        }}
        transition="slide-left"
      >
        <Stack spacing="xs" p="sm">
          {navLinks.map((links, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={i}>
              {links.map(({ label, href }) => (
                <UnstyledNextLink
                  className={commonClasses.link}
                  href={href}
                  key={href}
                  onClick={() => {
                    onMenuOpen();
                  }}
                  data-test-id={tc.components.appShellHeader.menuItem}
                >
                  {label}
                </UnstyledNextLink>
              ))}
              {i < navLinks.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Stack>
      </HeaderDrawer>
    </>
  );
}

export default MobileLogoutMenu;
